import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const OfferBottomSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  background: #7eb4d4;
  position: relative;
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ProductLinkTitle = styled.span`
  display: none;
`

const ShopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1160px;
  width: 100%;
  margin: 0px auto;
  padding: ${props => (props.padding ? props.padding : "0px 20px")};
  position: relative;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
  }
`

const ContentBox = styled.div`
  background: #fff;
  padding: 40px 30px;
  max-width: 560px;
  margin: 20px 0px;
  z-index: 1;
  position: relative;

  @media (max-width: 850px) {
    position: absolute;
    right: 0px;
    margin: 30px;
  }

  @media (max-width: 550px) {
    padding: 25px;
    margin: 20px 0px;
    position: relative;
  }
`

const TopBox = styled.div`
  width: 100%;
  margin-bottom: 15px;
`

const Title = styled.h2`
  font-size: 32px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  margin: 0px;
`

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  color: #262626;
  margin-bottom: 15px;
`

const DesktopProductImage = styled.img`
  width: 100%;
  margin: 0px;
  max-width: 550px;
  object-fit: contain;
  align-self: flex-end;

  @media (max-width: 1024px) {
    max-width: 450px;
  }

  @media (max-width: 850px) {
    max-width: 400px;
  }

  @media (max-width: 550px) {
    align-self: center;
    width: calc(100% + 40px);
  }
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props =>
    props.justify ? props.justify : "space-between;"};
  width: ${props => (props.width ? props.width : "100%")};
  z-index: 1;
  position: relative;
`

const StyledLink = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  font-size: 18px;
  margin-right: 15px;
  padding: 12px 20px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  max-width: 350px;

  :hover {
    color: ${props => props.theme.colors.white.base};
    background: ${props => props.theme.colors.button.black};
  }

  @media (max-width: 550px) {
    margin-right: 0px;
    max-width: 100%;
    padding: 12px 12px;
    font-size: 16px;
  }
`

const OfferBottomSection = ({ intl, data }) => {
  return (
    data && (
      <OfferBottomSectionWrapper>
        {data.button_text && data.button_url && (
          <ProductLink to={data.button_url}>
            <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
          </ProductLink>
        )}
        <ShopContainer>
          {data.image && (
            <DesktopProductImage
              className="lazyload"
              data-src={data.image}
              alt={data.alt ? data.alt : ""}
            />
          )}
          <ContentBox>
            {data.button_text && data.button_url && (
              <ProductLink to={data.button_url}>
                <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
              </ProductLink>
            )}
            {data.title && (
              <TopBox>
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              </TopBox>
            )}
            {data.text && (
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: data.text,
                }}
              />
            )}
            {data.button_text && data.button_url && (
              <FlexWrapper>
                <StyledLink to={data.button_url}>{data.button_text}</StyledLink>
              </FlexWrapper>
            )}
          </ContentBox>
        </ShopContainer>
      </OfferBottomSectionWrapper>
    )
  )
}

export default injectIntl(OfferBottomSection)
